@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.gradient-to-right {
  background: linear-gradient(-45deg, #0d9488, #14b8a6 51%, #0d9488) var(--x, 0) /
    200%;
  transition: all 0.5s;
}

.gradient-to-right:hover {
  --x: -100%;
}

@layer base {
  .underline-animation {
    @apply relative tracking-[1px] text-teal-600;
  }

  .underline-animation::after {
    @apply content-[''] bg-teal-600 h-[3px] w-[0%] left-0 -bottom-[5px] rounded-xl absolute duration-500;
  }

  .underline-animation:hover::after {
    @apply w-[100%];
  }
}

@media screen and (max-width: 767px) {
  .aspect-ratio {
    aspect-ratio: 5/3;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .aspect-ratio {
    aspect-ratio: 5/2;
  }
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(13, 148, 136, 0.5);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(13, 148, 136, 0);
  }
}
